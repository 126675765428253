import { useMemo } from 'react';
import { useUser } from './api/gql/user/queries/useUser';

export const useFeatureToggles = () => {
    const { data: userData } = useUser();

    const isFeaturePreviewMember = useMemo((): boolean => {
        if (!userData || !userData.groups) {
            return false;
        }

        return userData.groups.includes('BL_FeaturePreview');
    }, [userData]);

    return { isFeaturePreviewMember };
};
