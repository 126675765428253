import React, { PropsWithChildren } from 'react';
import { Tag } from '../Profile/infoboxVorlageTag/Tag';

export interface IProfileLabel extends PropsWithChildren {
    required?: boolean;
    tag?: string;
}

export const RmcLabel: React.FC<IProfileLabel> = (props) => {
    const { children, required = false, tag } = props;

    return (
        <>
            <span className="relative text-sm font-light uppercase leading-6 tracking-wider text-body-light">
                <span className="relative flex flex-row gap-3">
                    {required && tag && (
                        <>
                            <span className="relative flex flex-row gap-[1px]">
                                {children}
                                <span className="relative align-super text-danger">*</span>
                            </span>
                            <span className="relative top-[2px]">
                                <Tag text={tag} />
                            </span>
                        </>
                    )}

                    {required && !tag && (
                        <>
                            <span className="bg relative flex flex-row gap-[1px]">
                                {children}
                                <span className="relative align-super text-danger">*</span>
                            </span>
                        </>
                    )}

                    {!required && tag && (
                        <>
                            {children}
                            <span className="relative top-[2px]">
                                <Tag text={tag} />
                            </span>
                        </>
                    )}

                    {!required && !tag && <>{children}</>}
                </span>
            </span>
        </>
    );
};
