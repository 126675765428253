import { useCallback } from 'react';
import { useAllLocales } from './useAllLocales';

export const useLocales = () => {
    const locales = useAllLocales();

    const getLocale = useCallback(
        (key: string): string => {
            if (!locales) {
                return '';
            }

            const localesKeyValus: { [key: string]: string } = locales as unknown as {
                [key: string]: string;
            };

            if (localesKeyValus[key]) {
                return localesKeyValus[key] as unknown as string;
            }

            return (localesKeyValus?.[key] as string) ?? null;
        },
        [locales]
    );

    return { getLocale };
};
