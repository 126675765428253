import { useUser } from '../../hooks/api/gql/user/queries/useUser';
import { useCallback } from 'react';
import { useUserChangeFields } from '../../hooks/api/gql/user/mutations/useUserChangeFields';

export type TUserFieldToSave = { name: string; value: string }[];

export const useUserField = () => {
    const { data } = useUser();
    const { mutate: saveFields } = useUserChangeFields();

    const getField = useCallback(
        (fieldName: string) => {
            if (!data) return '';

            const entry = Object.entries(data.fields).find(([key]) => key === fieldName);
            if (entry) {
                return (entry[1] as string) ?? '';
            }

            return '';
        },
        [data]
    );

    const saveUserFields = useCallback(
        (fieldsToSave: TUserFieldToSave) => {
            saveFields(
                { fields: fieldsToSave },
                {
                    onSuccess: () => {},
                }
            );
        },
        [saveFields]
    );

    return { getField, saveUserFields };
};
