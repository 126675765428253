import React, { useState } from 'react';
import Image from 'next/legacy/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

interface IAvatar {
    image: string;
}

export const Avatar: React.FC<IAvatar> = (props) => {
    const { image } = props;

    const [fallbackImage, setFallbackImage] = useState(!image);

    return (
        <>
            <div
                className="group relative flex aspect-[1/1] h-full w-full overflow-hidden rounded-full border-2 border-primary"
                aria-hidden={true}
            >
                {!fallbackImage && (
                    <Image
                        src={image}
                        onError={() => setFallbackImage(true)}
                        layout="fill"
                        objectFit="cover"
                        alt="Avatar"
                    />
                )}

                {fallbackImage && (
                    <>
                        <figure className="flex h-full w-full items-center justify-center bg-neutral-200">
                            <FontAwesomeIcon icon={faUser} className="text-xl text-neutral-400" />
                        </figure>
                    </>
                )}
            </div>
        </>
    );
};
