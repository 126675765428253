import React, { PropsWithChildren, useMemo } from 'react';

interface IBadge extends PropsWithChildren {
    color?: 'primary' | 'primary-light' | 'secondary' | 'default' | 'danger';
}

export const Badge: React.FC<IBadge> = (props) => {
    const { color = 'secondary', children } = props;

    const colorUsed = useMemo(() => {
        switch (color) {
            case 'primary':
                return 'bg-primary';
            case 'primary-light':
                return 'bg-primary-light';
            case 'secondary':
                return 'bg-secondary';
            case 'danger':
                return 'bg-red-700';
        }

        return 'bg-neutral-500';
    }, [color]);

    return (
        <figure
            className={`relative flex aspect-[1/1] h-6 w-6 items-center justify-center rounded-full ${colorUsed}`}
        >
            <span className="font-medium text-xs text-white">{children}</span>
        </figure>
    );
};
