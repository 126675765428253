import React, { useCallback, useRef } from 'react';
import { useHoverDirty } from 'react-use';
import { Transition } from '@headlessui/react';
import { useRecoilState } from 'recoil';
import { navigationShopAtom } from './recoil/shopNavigation';
import { useNavigationHandler } from './hooks/useNavigationHandler';

interface ISubHeaderButton {
    label: string;
    tab: number;
}

export const SubHeaderButton: React.FC<ISubHeaderButton> = (props) => {
    const { label, tab } = props;

    const [navState, setNavState] = useRecoilState(navigationShopAtom);
    const { resetNavigation } = useNavigationHandler();

    const ref = useRef<HTMLButtonElement | null>(null);

    // @ts-ignore
    const isHovering = useHoverDirty(ref);

    const click = useCallback(() => {
        resetNavigation();
        setNavState({ ...navState, tab: tab, showTopics: false, showShop: true });
    }, [navState, resetNavigation, setNavState, tab]);

    return (
        <>
            <button
                onClick={() => click()}
                ref={ref}
                className="relative flex w-min flex-nowrap whitespace-nowrap"
            >
                <Transition
                    as={'div'}
                    show={isHovering}
                    className="absolute bottom-0 w-0 border-b border-primary"
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 w-0"
                    enterTo="opacity-100 w-full"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                />
                <span className="text-sm font-medium uppercase text-neutral-600 transition-all hover:text-primary">
                    {label}
                </span>
            </button>
        </>
    );
};
