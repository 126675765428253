import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from '../../atoms/Modal/Modal';
import { Typography } from '../../typo/Typography';
import { Button } from '../../Button/Button';
import { useLocales } from '../../../hooks/locale/useLocales';
import { useAllLocales } from '../../../hooks/locale/useAllLocales';
import { useUserField } from '../../userFields/useUserField';
import { useHasState } from '../hooks/useHasState';

export const ProfileTaxModal6: React.FC = () => {
    const { getLocale } = useLocales();
    const locales = useAllLocales();
    const { saveUserFields } = useUserField();
    const { hasState } = useHasState();
    const [show, setShow] = useState(false);

    const handleConfirm = useCallback(() => {
        saveUserFields([
            {
                name: 'Invoice_Tax_State',
                value: '7',
            },
        ]);
    }, [saveUserFields]);

    useEffect(() => {
        if (hasState([6])) {
            setShow(true);
        }
    }, [hasState]);

    return (
        <Modal show={show} afterClose={() => {}} closeable={false}>
            {locales && (
                <div className="flex flex-col gap-6 p-4">
                    <Typography variant="h1-green">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: getLocale('LL_PopUp_WaWi_ValdierungInfo_S6_H1_DE'),
                            }}
                        />
                    </Typography>
                    <Typography variant="h3">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: getLocale('LL_PopUp_WaWi_ValdierungInfo_S6_H2_DE'),
                            }}
                        />
                    </Typography>

                    <Typography variant="paragraph">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: getLocale('LL_PopUp_WaWi_ValdierungInfo_S6_C1_DE'),
                            }}
                        />
                    </Typography>

                    <div className="flex flex-col gap-4 mt-4 ">
                        <Button color="primary" onClick={handleConfirm}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: getLocale('LL_PopUp_WaWi_ValdierungInfo_S5_B1_DE'),
                                }}
                            />
                        </Button>
                    </div>
                </div>
            )}
        </Modal>
    );
};
