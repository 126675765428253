import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from '../../atoms/Modal/Modal';
import { Typography } from '../../typo/Typography';
import { Button } from '../../Button/Button';
import { useLocales } from '../../../hooks/locale/useLocales';
import { useAllLocales } from '../../../hooks/locale/useAllLocales';
import { useUserField } from '../../userFields/useUserField';
import { useRouter } from 'next/router';
import { useHasState } from '../hooks/useHasState';

export const ProfileTaxModal1: React.FC = () => {
    const { push } = useRouter();

    const { getLocale } = useLocales();
    const locales = useAllLocales();
    const { saveUserFields } = useUserField();
    const { hasState } = useHasState();
    const [show, setShow] = useState(false);

    const handleJumpToProfile = useCallback(() => {
        saveUserFields([
            {
                name: 'Invoice_Tax_State',
                value: '2',
            },
        ]);

        void push('/profile');
    }, [push, saveUserFields]);

    const handleHideModal = useCallback(() => {
        saveUserFields([
            {
                name: 'Invoice_Tax_State',
                value: '2',
            },
        ]);
    }, [saveUserFields]);

    useEffect(() => {
        if (hasState([1])) {
            setShow(true);
        }
    }, [hasState]);

    return (
        <Modal show={show} afterClose={() => {}} closeable={false}>
            {locales && (
                <div className="flex flex-col gap-6 p-4">
                    <Typography variant="h1-blue">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: getLocale('LL_PopUp_WaWi_ValdierungInfo_S1_H1_DE'),
                            }}
                        />
                    </Typography>
                    <Typography variant="h2-red">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: getLocale('LL_PopUp_WaWi_ValdierungInfo_S1_H2_DE'),
                            }}
                        />
                    </Typography>

                    <Typography variant="paragraph">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: getLocale('LL_PopUp_WaWi_ValdierungInfo_S1_C1_DE'),
                            }}
                        />
                    </Typography>

                    <Typography variant="h2-red">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: getLocale('LL_PopUp_WaWi_ValdierungInfo_S1_C2_DE'),
                            }}
                        />
                    </Typography>
                    <Typography variant="paragraph">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: getLocale('LL_PopUp_WaWi_ValdierungInfo_S1_C3_DE'),
                            }}
                        />
                    </Typography>
                    <div className="flex flex-col gap-4 mt-4">
                        <Button color="primary" onClick={handleJumpToProfile}>
                            !!! Validierung und Freischaltung im Benutzerprofil starten
                        </Button>

                        <Button color="default" onClick={handleHideModal}>
                            !!! Meldung nicht mehr anzeigen, dauerhaft ausblenden
                        </Button>
                    </div>
                </div>
            )}
        </Modal>
    );
};
