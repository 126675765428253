import React from 'react';

export interface ITag {
    text?: string;
}
export const Tag: React.FC<ITag> = (props) => {
    const { text = '' } = props;

    return (
        <>
            <div className="relative flex h-5 w-max items-center justify-center rounded-md bg-success/10 px-2">
                <span className="whitespace-nowrap text-[10px] uppercase text-success">{text}</span>
            </div>
        </>
    );
};
