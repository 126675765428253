import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from '../../atoms/Modal/Modal';
import { Typography } from '../../typo/Typography';
import { Button } from '../../Button/Button';
import { useLocales } from '../../../hooks/locale/useLocales';
import { useAllLocales } from '../../../hooks/locale/useAllLocales';
import { useUserField } from '../../userFields/useUserField';
import { useHasState } from '../hooks/useHasState';

export const ProfileTaxModal5: React.FC = () => {
    const { getLocale } = useLocales();
    const locales = useAllLocales();
    const { saveUserFields, getField } = useUserField();
    const { hasState } = useHasState();
    const [show, setShow] = useState(false);

    const handleConfirm = useCallback(() => {
        saveUserFields([
            {
                name: 'Invoice_Tax_State',
                value: '1',
            },
        ]);
    }, [saveUserFields]);

    useEffect(() => {
        if (hasState([5])) {
            setShow(true);
        }
    }, [hasState]);

    return (
        <Modal show={show} afterClose={() => {}} closeable={false}>
            {locales && (
                <div className="flex flex-col gap-6 p-4">
                    <Typography variant="h1-red">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: getLocale('LL_PopUp_WaWi_ValdierungInfo_S5_H1_DE'),
                            }}
                        />
                    </Typography>
                    <Typography variant="h2-red">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: getLocale('LL_PopUp_WaWi_ValdierungInfo_S5_H2_DE'),
                            }}
                        />
                    </Typography>

                    <Typography variant="paragraph">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: getLocale('LL_PopUp_WaWi_ValdierungInfo_S5_C1_DE'),
                            }}
                        />
                    </Typography>

                    <Typography variant="paragraph">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: getLocale('LL_PopUp_WaWi_ValdierungInfo_S5_C2_DE'),
                            }}
                        />
                    </Typography>

                    <div className="flex flex-col gap-2 w-full mt-2">
                        <textarea
                            className="w-full h-32 bg-gray-100 border-2 border-gray-200 py-2 px-2 rounded"
                            disabled={true}
                            readOnly={true}
                            value={getField('Invoice_Tax_Cancelation_Reason')}
                        />
                    </div>

                    <div className="flex flex-col gap-4 mt-4 max-w-[500px]">
                        <Button color="secondary" onClick={handleConfirm}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: getLocale('LL_PopUp_WaWi_ValdierungInfo_S5_B1_DE'),
                                }}
                            />
                        </Button>
                    </div>
                </div>
            )}
        </Modal>
    );
};
