import React, { PropsWithChildren, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Link from 'next/link';
import { Transition } from '@headlessui/react';
import { useHoverDirty } from 'react-use';

interface IUserContextButton extends PropsWithChildren {
    icon?: IconProp;
    iconJSX?: JSX.Element;
    label: string;
    href?: string;
    target?: string;
    onClick?: () => void;
    color?: 'primary-light' | 'default';
    iconColor?: 'red' | 'default';
}

export const UserContextButton: React.FC<IUserContextButton> = (props) => {
    const {
        icon,
        children,
        label,
        href,
        target = '_self',
        onClick,
        color = 'default',
        iconColor = 'default',
        iconJSX,
    } = props;

    const ref = useRef<HTMLAnchorElement | null>(null);

    // @ts-ignore
    const isHovering = useHoverDirty(ref);

    return (
        <>
            {href && href !== '' && (
                <>
                    <Link
                        href={href ?? ''}
                        target={target}
                        className="group relative flex w-full flex-row items-center gap-2"
                        ref={ref}
                        onClick={() => {
                            if (onClick) onClick();
                        }}
                    >
                        <Transition
                            as={'div'}
                            show={isHovering}
                            className="absolute bottom-0 w-0 border-b border-white"
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 w-0"
                            enterTo="opacity-100 w-full"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        />
                        <div className="flex h-12 w-12 items-center justify-center ">
                            {icon && (
                                <FontAwesomeIcon
                                    icon={icon}
                                    className={`h-6 ${
                                        iconColor === 'red' ? 'text-red-600' : 'text-white'
                                    } `}
                                />
                            )}

                            {iconJSX && <div className="relative flex h-full">{iconJSX}</div>}
                        </div>
                        {children}
                        <span
                            className={`inline-flex h-full items-center text-left font-medium text-lg tracking-wide opacity-90 group-hover:opacity-100 ${
                                color === 'primary-light' ? 'text-primary-light' : 'text-white'
                            }`}
                            dangerouslySetInnerHTML={{ __html: label }}
                        ></span>
                    </Link>
                </>
            )}

            {onClick && !href && (
                <>
                    <button
                        onClick={() => {
                            if (onClick) onClick();
                        }}
                    >
                        <span
                            className="group relative flex w-full flex-row items-center gap-2"
                            ref={ref}
                        >
                            <Transition
                                as={'div'}
                                show={isHovering}
                                className="absolute bottom-0 w-0 border-b border-white"
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 w-0"
                                enterTo="opacity-100 w-full"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            />
                            <div className="flex aspect-[1/1] h-12 w-12 items-center justify-center ">
                                {icon && (
                                    <FontAwesomeIcon
                                        icon={icon}
                                        className={`h-6 ${
                                            iconColor === 'red' ? 'text-red-600' : 'text-white'
                                        } `}
                                    />
                                )}

                                {iconJSX && <div className="relative flex h-full">{iconJSX}</div>}
                            </div>
                            {children}
                            <span
                                className={`inline-flex h-full items-start text-left font-medium text-lg tracking-wide opacity-90 group-hover:opacity-100 ${
                                    color === 'primary-light' ? 'text-primary-light' : 'text-white'
                                }`}
                                dangerouslySetInnerHTML={{ __html: label }}
                            />
                        </span>
                    </button>
                </>
            )}
        </>
    );
};
