import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useApi } from '../../../useApi';
import { IUserFromDB } from '../../../../../interfaces/IUser';
import { mapUserFromDbToFe } from '../mapUserFromDbToFe';

export const useUserChangeFields = () => {
    const { postGql } = useApi();

    const queryClient = useQueryClient();

    return useMutation(
        async (input: { fields: { name: string; value: string }[] }) => {
            const { changeFields } = await postGql<{ changeFields: IUserFromDB }>(
                gql`
                    mutation ($input: ChangeFieldsInput!) {
                        changeFields(input: $input) {
                            id
                            userName
                            email
                            language
                            exemptFromVat
                            thumbnail
                            fields {
                                name
                                value
                            }
                            groups
                        }
                    }
                `,
                { input },
                'changeFields'
            );
            return mapUserFromDbToFe(changeFields);
        },
        {
            onSuccess: (user) => {
                queryClient.setQueryData(['user'], user);
                void queryClient.invalidateQueries(['profileCompletion']);
            },
        }
    );
};
