import React from 'react';
import { ProfileTaxModal1 } from './modals/ProfileTaxModal1';
import { ProfileTaxModal3 } from './modals/ProfileTaxModal3';
import { ProfileTaxModal5 } from './modals/ProfileTaxModal5';
import { ProfileTaxModal6 } from './modals/ProfileTaxModal6';
import { useFeatureToggles } from '../../hooks/useFeatureToggles';
import { useHasState } from './hooks/useHasState';

export const ProfileTaxModalDispatcher: React.FC = () => {
    const { isFeaturePreviewMember } = useFeatureToggles();

    const { hasState } = useHasState();

    return (
        <>
            {isFeaturePreviewMember && (
                <>
                    {hasState([1]) && <ProfileTaxModal1 />}
                    {hasState([3]) && <ProfileTaxModal3 />}
                    {hasState([5]) && <ProfileTaxModal5 />}
                    {hasState([6]) && <ProfileTaxModal6 />}
                </>
            )}
        </>
    );
};
