import { useUserField } from '../../userFields/useUserField';
import { useCallback } from 'react';

export const useHasState = () => {
    const { getField } = useUserField();

    const hasState = useCallback(
        (states: number[]): boolean => {
            const stateInField = getField('Invoice_Tax_State');
            return states.some((state) => state.toString() === stateInField);
        },
        [getField]
    );

    return { hasState };
};
