import React, { PropsWithChildren, useMemo } from 'react';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';

export interface IButton extends PropsWithChildren {
    onClick?: () => void;
    href?: string;
    target?: string;
    addtionalCss?: string;
    color?: 'primary' | 'primary-light' | 'secondary' | 'default' | 'danger' | 'success';
    iconEnd?: IconDefinition;
    iconStart?: IconDefinition;
    iconSpin?: boolean;
    disabled?: boolean;
    alignWithFormFields?: boolean;
    height?: string;
}

export const Button: React.FC<IButton> = (props) => {
    const {
        children,
        addtionalCss,
        onClick,
        color = 'default',
        iconEnd,
        iconStart,
        href,
        target = '_self',
        disabled = false,
        iconSpin = false,
        height,
        alignWithFormFields = false,
    } = props;

    const colorUsed = useMemo(() => {
        switch (color) {
            case 'primary':
                return 'bg-primary hover:bg-primary-light';
            case 'primary-light':
                return 'bg-primary-light hover:bg-primary';
            case 'secondary':
                return 'bg-secondary hover:bg-secondary-light';
            case 'danger':
                return 'bg-red-700 hover:bg-red-700/60';
            case 'success':
                return 'bg-success hover:bg-success/60';
        }

        return 'bg-neutral-300 hover:bg-neutral-200';
    }, [color]);

    const textColorUsed = useMemo(() => {
        switch (color) {
            case 'primary':
                return 'text-white';
            case 'primary-light':
                return 'text-white';
            case 'secondary':
                return 'text-white';
            case 'danger':
                return 'text-white';
            case 'success':
                return 'text-white';
        }

        return 'text-neutral-600';
    }, [color]);

    const iconSpinStyle = useMemo(() => {
        return iconSpin ? 'animate-spin' : '';
    }, [iconSpin]);

    return (
        <div className="relative flex w-full flex-col gap-0">
            {alignWithFormFields && <div className="relative block h-[26px]" />}

            {onClick && !href && (
                <button
                    onClick={onClick}
                    role="button"
                    disabled={disabled}
                    className={`relative flex w-full items-center justify-center gap-2 rounded px-4 py-2 uppercase ${colorUsed}  ${
                        addtionalCss ?? ''
                    } ${height ?? 'h-10'} ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                >
                    {iconStart && (
                        <FontAwesomeIcon
                            icon={iconStart}
                            className={`${textColorUsed} ${iconSpinStyle} h-4`}
                        />
                    )}
                    <span className={`text-sm ${textColorUsed}`}> {children}</span>
                    {iconEnd && (
                        <FontAwesomeIcon
                            icon={iconEnd}
                            className={`${textColorUsed} ${iconSpinStyle}`}
                        />
                    )}
                </button>
            )}

            {href && (
                <Link
                    href={href}
                    onClick={onClick}
                    target={target}
                    className={`relative flex items-center justify-center gap-2 rounded px-4 py-1 uppercase ${colorUsed} h-10 ${
                        addtionalCss ?? ''
                    }`}
                >
                    {iconStart && (
                        <FontAwesomeIcon
                            icon={iconStart}
                            className={`${textColorUsed} ${iconSpinStyle} h-4`}
                        />
                    )}
                    <span className={`text-sm ${textColorUsed}`}> {children}</span>
                    {iconEnd && (
                        <FontAwesomeIcon
                            icon={iconEnd}
                            className={`${textColorUsed} ${iconSpinStyle}`}
                        />
                    )}
                </Link>
            )}
        </div>
    );
};
