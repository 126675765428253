import React from 'react';

export interface ITypography {
    variant:
        | 'h1-blue'
        | 'h1-red'
        | 'h1-green'
        | 'h2-blue'
        | 'h2-red'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'h6'
        | 'description'
        | 'label'
        | 'label-tiny'
        | 'paragraph';
    className?: string;
    children?: React.ReactNode;
}

export const Typography: React.FC<ITypography> = (props) => {
    const { variant, children, className } = props;

    return (
        <>
            {variant === 'h1-blue' && (
                <h1 className={`font-bold text-primary text-xl lg:text-4xl ${className ?? ''}`}>
                    {children}
                </h1>
            )}

            {variant === 'h1-red' && (
                <h1 className={`font-bold text-secondary text-xl lg:text-4xl ${className ?? ''}`}>
                    {children}
                </h1>
            )}
            
            {variant === 'h1-green' && (
                <h1 className={`font-bold text-success text-xl lg:text-4xl ${className ?? ''}`}>
                    {children}
                </h1>
            )}

            {variant === 'h2-blue' && (
                <h2
                    className={`break-words text-xl font-bold uppercase leading-6 text-primary ${
                        className ?? ''
                    }`}
                >
                    {children}
                </h2>
            )}

            {variant === 'h2-red' && (
                <h2
                    className={`break-words text-xl font-bold uppercase leading-6 text-secondary ${
                        className ?? ''
                    }`}
                >
                    {children}
                </h2>
            )}

            {variant === 'h3' && (
                <h3 className={`font-bold text-neutral-400 text-2xl ${className ?? ''}`}>
                    {children}
                </h3>
            )}
            {variant === 'h4' && (
                <h4 className={`text-xl text-neutral-400 font-semibold ${className ?? ''}`}>
                    {children}
                </h4>
            )}

            {variant === 'h5' && (
                <h5 className={`text-lg font-medium ${className ?? ''}`}>{children}</h5>
            )}

            {variant === 'h6' && (
                <h6 className={`text-sm font-medium ${className ?? ''}`}>{children}</h6>
            )}
            {variant === 'description' && (
                <p className={`text-xs font-normal lg:text-sm ${className ?? ''}`}>{children}</p>
            )}
            {variant === 'label' && (
                <span className={`lg:text-md text-xs font-medium ${className ?? ''}`}>
                    {children}
                </span>
            )}
            {variant === 'label-tiny' && (
                <span className={`text-[9px] ${className ?? ''}`}>{children}</span>
            )}

            {variant === 'paragraph' && (
                <span className={`font-base leading-5 text-body ${className ?? ''}`}>
                    {children}
                </span>
            )}
        </>
    );
};
